import { signOut, getAuth } from "firebase/auth";
import { type RouteLocationNormalizedLoaded, createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from "vuefire";
import { useUserStore } from "@/store/user";

export const isCurrentRouteAuthenticated = (route: RouteLocationNormalizedLoaded) => {
  return route.meta.requiresAuth;
};

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layouts/SideMenu.vue"),
    children: [
      {
        path: "/projects",
        name: "Projects",
        component: () => import("@/views/Projects/Projects.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/settings",
        name: "ClientSettings",
        redirect: { name: "ClientAccount" },
        component: () => import("@/views/ClientSettings/ClientSettings.vue"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "account",
            name: "ClientAccount",
            component: () => import("@/views/ClientSettings/views/Account.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "users",
            name: "ClientUsers",
            component: () => import("@/views/ClientSettings/views/Users.vue"),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "project/:projectId?",
        name: "Project",
        redirect: { name: "Dashboard" },
        component: () => import("@/views/Project/Project.vue"),
        meta: { requiresAuth: true },
        props: true,
        children: [
          {
            path: "transactions",
            name: "Transactions",
            component: () => import("@/views/Project/views/Transactions/Transactions.vue"),
            meta: { requiresAuth: true },
            props: true,
          },
          {
            path: "dashboard",
            name: "Dashboard",
            component: () => import("@/views/Project/views/Dashboard/Dashboard.vue"),
            meta: { requiresAuth: true },
            props: true,
          },
          {
            path: "notifications",
            name: "Notifications",
            component: () => import("@/views/Project/views/Notifications.vue"),
            meta: { requiresAuth: true },
            props: true,
          },
          {
            path: "settings",
            name: "Settings",
            component: () => import("@/views/Project/views/Settings/Settings.vue"),
            meta: { requiresAuth: true },
            props: true,
          },
          {
            path: "transfers",
            name: "Transfers",
            component: () => import("@/views/Project/views/Transfers.vue"),
            meta: { requiresAuth: true },
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/link",
    name: "Link",
    props: true,
    component: () => import("@/views/Link.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/views/Signup.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    meta: { requiresAuth: true, requiresAdmin: true },
    component: () => import("@/views/Admin/Clients.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const userStore = useUserStore();

  // Set clientId from query parameters (for admin links)
  if (to.query.clientId && typeof to.query.clientId === "string") {
    userStore.clientId = to.query.clientId;
  }

  if (to.meta.requiresAuth || to.meta.requiresAdmin) {
    const currentUser = await getCurrentUser();

    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      };
    }

    const { claims } = await currentUser.getIdTokenResult();

    if (to.meta.requiresAdmin) {
      if (!claims.admin) {
        return {
          path: "/login",
        };
      } else {
        return;
      }
    }

    if (to.meta.requiresAuth && !claims.clientId && !claims.admin) {
      signOut(getAuth());
    }
  }
});

export default router;
